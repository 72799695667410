.btn-open-link-menu {
    color: white !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 15px 20px !important;
    display: flex !important;
    align-items: center !important;
    background: #2C3354 !important;
}

.btn-open-link-menu > p {
    color: #F3A751 !important;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: left;
    padding-left: 20px;
}

.submenu-container{
    /* background-color: #2c3354; */
    background: transparent !important;
}

.submenu-btn{
    width: 100% !important;
    text-align: left;
    border-radius: 0px;
    padding: 12px
}

.submenu-btn:hover{
    background-color: #2C3354 !important;
}

.submenu{
    /* background-color: #2c335456 !important; */
    background: transparent !important;
    margin-bottom: 5px;
    color: #F3A751 !important;
}

.fa-arrow-right {
    font-size: 14px;
    margin-right: 5px;
}

