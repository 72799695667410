@media screen and (max-width: 400px){
    .btn-block{
        display: block;
        width: 100%;
        margin: 0 0 10px !important;
    }
}

.z-index-2{
    z-index: 20000 !important;
}