@import url(https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Condensed&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@500&display=swap);
.btn-open-link-menu {
    color: white !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 15px 20px !important;
    display: flex !important;
    align-items: center !important;
    background: #2C3354 !important;
}

.btn-open-link-menu > p {
    color: #F3A751 !important;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: left;
    padding-left: 20px;
}

.submenu-container{
    /* background-color: #2c3354; */
    background: transparent !important;
}

.submenu-btn{
    width: 100% !important;
    text-align: left;
    border-radius: 0px;
    padding: 12px
}

.submenu-btn:hover{
    background-color: #2C3354 !important;
}

.submenu{
    /* background-color: #2c335456 !important; */
    background: transparent !important;
    margin-bottom: 5px;
    color: #F3A751 !important;
}

.fa-arrow-right {
    font-size: 14px;
    margin-right: 5px;
}


@charset "utf-8";

:root{
	--azul: rgb(42, 23, 216);
	--preto:#000;
}

*{
	margin:0;
	padding:0;
	box-sizing: border-box;
	font-family: 'Roboto-Regular', sans-serif, Verdana, Tahoma, sans-serif;
}

body{
	min-height: 100vh;	  
}
/* ******************************************************************* */
.botaoLink{
	text-decoration: none;
	font-size: 20px;
	color: #6AA9D9;
	border: 2px solid #6AA9D9;
	border-radius: 23px;
	padding: 5px 15px;
}

.vertical-nav {	
	/* width: 15rem;
	height: 100vh; */
	max-width: 240px;
	min-width: 240px;
	box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
	transition: all 0.4s;
	background: #707070;	
	/* position:fixed; */
	/* overflow: hidden; */
  }
  .page-content {
	/* width: calc(100% - 15rem); */
	/* margin-left: 15rem; */
	transition: all 0.4s;
  } 
  
  #sidebar.active {
	margin-left: -15rem;
	z-index: 999 !important;
}
  
  #content.active {
	width: 100%;
	margin: 0;
  }

  #btnCloseMenu{
	  display: none;
  }
@media (max-width: 768px) {
	.logoParceriaDescricao, .separadorLogo{
		display: none;
	}

	#sidebar {
	  margin-left: -15rem;
	  
	}
	#sidebar.active {
	  margin-left: 0;
	}
	#sidebar.active > #btnCloseMenu{
		display: block;
	}
	#content {
	  width: 100%;
	  margin: 0;
	}
	#content.active {
	  /* margin-left: 15rem; */
	  width: calc(100% - 15rem);
	}

}
/* ******************************************************************* */
.fundoMenu{
	background: #707070;	
}

ul li a i{
	color: #F5F5F5;	
	font-size: 25px;
}

.menuPrincipalAjuste{
	text-decoration: none;
	background: #2C3354;
	line-height: 50px;
	width: 100%;	
}

.menuPrincipalAjuste a{
	color: #FFFFFF;
	height: 50px;
	border: none;
	font-size: 15px;
	width: 100%;
	align-items: flex-start;
}

.menuPrincipalAjuste a:hover{
	color: #FFFFFF;
}

.logoutMobile{
	display:none;
	background: #5E699D;
	color: #FFFFFF;
}

.logoutMobile i{
	padding-right: 5px;
	padding-top: 5px;
	height: 30px;
}

.menuPrincipalAjuste button{
	color: #F3A74F;
	height: 50px;
	border: none;
	font-size: 15px;
	width: 100%;
	align-items: flex-start;
}

.menuPrincipalAjuste button i{
	padding-right: 5px;
	color: #F5F5F5;
}

.menuPrincipalAjuste button:hover{
	color: #F3A74F;

}

.iconeGoogleDrive {
	padding-left: 5px;
}

.menuPrincipalAjuste div{
	display: block;
	text-decoration: none;
	color: #2C3354;
	background: #051564;
}

.menuPrincipalAjuste div ul li{
	background: #051564;
}
.menuPrincipalAjuste div ul li a{
	color: #FFFFFF;
	text-decoration: none;
	margin: 0 0;
	text-align: left;
}

.menuPrincipalAjuste div ul li:hover{
	background: #5E699D;
}

.menuPrincipalAjuste div ul li a:hover{
	background: #5E699D;
	color: #FFFFFF;
}

/* Topo área pública */
header .acesso { 
  	display: flex;
  	flex-direction: row-reverse;	
  	align-items: flex-end;
  	justify-self: right;	
}

header .acesso a {
	text-decoration: none;
	color: #000;
	color: var(--preto);	
  	margin-top: 3px;
  	margin-right: 10px;
  	font-size: 15px;
}

header .acesso a:hover {
	color: rgb(42, 23, 216);
	color: var(--azul);
}

header .container{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;	
	margin-top: 10px;
}

header .logo{	  
  font-size: 50px;  
  margin-top: 15px;
}

header nav {
	margin-right: 15px;
}

header nav a {
	text-decoration: none;
	color: #000;
	color: var(--preto);
	padding: 5px 6px;
}

header nav a:hover {
	color: rgb(42, 23, 216);
	color: var(--azul);
}

.fundoModal{
	background: #F2F3F7;
}

.fundoAreaTrabalho{
	background: #F2F3F7;
	min-height: 100vh;		
}

.painelInicial{
	height: 100vh;
}

.profile{
	height:'100px';
	width:'100px';
	
}
/*  Login */
.logoImgUFMG{
	width: 15%;
	margin-left: 1.5rem;
}
.logoImgRAB{
	width: 45%;
}

.logoImgRABIcone{
	width: 16%;
}
 .containerLogin{
	opacity: 1.0;
	height:100vh;
 
}

.logoParceria{
	letter-spacing: 0px;
	color:#f39200;
	text-align: left;
	font-family: 'Barlow', sans-serif;
	font-size: 25px;
	font-style: normal;
	font-weight: 300px;
	opacity: 1;
	padding: 0.3em;		
	justify-content: space-between;
	

}
.separadorLogo{	
	margin-left: 15px;
	border-left: 6px solid #C80019;
  	height: 60px;
}

.logoParceriaDescricao {
	letter-spacing: 0px;
	color: #000000;
	text-align: left;
	font-family: 'Roboto-Regular', sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 300px;
	opacity: 1;	
	margin-left: 5px;
}
.mobile{
	display: none;
}

.logos{
	display: flex;
}

.logout a, .logout a:hover{
	color: #FFFFFF;
	text-decoration: none;
	align-items: flex-end;
	justify-content: end;
	font-size: 20px;
	vertical-align: sub;
	
}

.logoUFMG{

	height: 50%;
}

.logoRAB{
	height: 100%;
}

.fundoBoasVindas{
	min-height: 100vh;
	background-color: rgba(228,228,228,1);
}

.container-xl-fluid{
	display: flex;
	align-items: stretch;
	box-sizing: border-box;
}

.boasvindas{
	background: url(/static/media/boasvindas.ac0e742e.png) no-repeat center;
	background-position-y:  160px;
	height: 80%;
};
.olaboasvinda{
    white-space: nowrap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 80px;
    color: rgba(112,112,112,1);
	margin-bottom: 0;
}

.feedback{
	border: none;
	margin-right: 0;
	padding-right: 0;
	margin-top: 0;
	padding-top: 0;
	position: top;
	justify-content: right;
	background: #5E699D;
	
}

.formularioLogin{
	display: flex;	
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.formularioLogin form{

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;	
	width: 30%;
	max-width: 400px;
	min-width: 240px;		
	background: #F5F5F5;
	border-radius: 12px;
	box-shadow: 0px 20px 20px #00000029;
	border: 1px solid #707070;
	opacity: 1;
	padding-top: 10px;

}

.formularioLogin form div {
	width: 80%;	
	opacity: 1;
}

.formularioLogin form div label{	
	margin-left: 15px;
	text-align: left;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
	font-size: 11px;
	font-family: 'Open Sans', sans-serif;
	
}

.formularioLogin form div label .primeiro, .label{
	margin-top: 20px;		
}

.formularioLogin form div input, .email{
	
	background: #FFFFFF;
	border-radius: 23px;
	opacity: 1;
	padding-left: 15px;
}

.esqueceu{	
	text-align: left;
	letter-spacing: 0px;
	
	opacity: 1;
	font-size: 11px;
	font-family: 'Open Sans', sans-serif;

}

.esqueceu a{	
	text-decoration: none;
	color: #DFDFDF;
}

.form-check-input{
	padding-left: 0;
}
.botaoLogin{
	
	background: #2BC706 0% 0% no-repeat padding-box;
	border:none;
	border-radius: 23px;
	opacity: 1;
	width: 100%;
	font-size: 19px	;
	color: #FFFFFF;
	height: 36px;
	margin-top: 5px;
	
}
.botaoLogin:disabled{
	opacity: 0.5 !important;
	cursor: not-allowed !important;
}

.grupo{
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	background-color: #F5F5F5;
	border: 1px solid black;
	border-radius: 10px;
}

.grupo:nth-child(1){
	min-height: 10rem;
}

.grupo p {
	margin: 1rem;
}

.erroLogin{
	visibility: hidden;
}

.grupo p input{
		width: 100%;
		padding: 12px 20px;
		margin: 8px 0;		
		border-radius: 23px;
		box-sizing: border-box;	
}

.grupo p span {
	margin-top: 1rem;
}

.grupo p span input{
	background: #4CAF50;
	width: 100%;
	border: 0;
	color: #FFFFFF;
	font-size: 14px;
	font-weight: bolder;
	cursor: pointer;
	display: inline-block;	
	box-sizing: border-box;	

}

.grupo span a{
	text-decoration: none;
	color: #000;
	color: var(--preto);
}

.grupo span a:hover{
	color: rgb(42, 23, 216);
	color: var(--azul);
}

/* PAINEL */
.cabecalhoPainel{
	background: #5E699D;
	min-height: 50px;
}

/* MENU */
  /* The container <div> - needed to position the dropdown content */
  .dropdownContainer {
	margin-top: 1em;
	position: relative;
	display: inline-block;
  }

.tamanho{
	width: 100%;
	margin-top: 10px;
}

.tamanho_item{
	min-width: 200px;;
}
.dropbtn {
	background-color: darkblue;
	color: white;
	border: none;
	border-radius: 4%;
	padding: 15px;
	width: 100%;
	
  }
 
  /* Dropdown Content (Hidden by Default) */
  .dropdown-conteudo {
	display: none;
	position: absolute;
	background-color: #f1f1f1;
	width: 200px;	
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-conteudo a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-conteudo a:hover {background-color: #ddd;}
  
  /* Show the dropdown menu on hover */
  .dropdownContainer:hover .dropdown-conteudo {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdownContainer:hover .dropbtn {background-color: #3e8e41;}

@media screen and (max-width:844px){
  
  header .container{
		display: flex;
		flex-direction: column;		
	}
	
	header nav{
		margin-top: 12px;
		margin-bottom: 12px;
	}

	.containerLogin form{
		width: 100%;
	}

	.mobile{
		display: flex;
		font-family: 'Barlow', sans-serif;
			font-size: 30px;
			height: auto;
		
	}

	.logout{
		display: none;
	}
	
	.logoutMobile{
		display: flex;
	}

	.logos{
		display: none;
	}

	.feedback{
		display: none;
	}

	.logoUFMG{
		display: none;
	}
	
	.logoRAB{
		display: none;
	}
}

@media screen and (max-width:525px){

	.feedback{
		display: none;
	}
	.logout{
		display: none;
	}
	.logoutMobile{
		display: flex;
	}
	header nav{
		display: none;
	}

 	header .acesso{
		display: none;
	}

	.containerLogin form{
		width: 100%;
	}

	.mobile{
		display: block;
		font-family: 'Barlow', sans-serif;
		font-size: 18px;
		height: auto;
	}


	.logos{
		display: none;
	}

	.logoUFMG{
		display: none;
	}
	
	.logoRAB{
		display: none;
	}
}

/* PAGINAÇÃO */
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
	justify-content: center;
  }
  .pagination > .active > a{
    background-color: #000000 ;
    border-color: #000000 ;
    color: #fff;
  }
  .pagination > li > a{
    border: 1px solid #000000 ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #47ccde ;
    border-color: #47ccde;
    outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
    color: #47ccde
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
  }
.user-image-container {
    position: relative;
    width: 115px;
    height: 115px;
    border: 5px solid lightgray;
    border-radius: 50%;
    margin: 0 auto;
    cursor: pointer;

    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.user-image-container:hover{
    background-image: none !important;
    background-color: #000;
}
.user-image-container:hover > i{
    display: block;
}

.user-image-container > i:hover{
    opacity: 1;
}

.user-image-container > i{
    color: #fff;
    position: absolute;
    left: 30px;
    top: 30px;
    display: none;
}

.user-image-container > input{
    width: 0;
    opacity: 0;
}

.required::after {
    content: "*";
    color: red;
}

.nav-tabs-e {
    background-color: #5e699d !important;
    color: white !important;
    border-color: #5e699d !important;
    /* border-bottom: none !important; */
}
.nav-link-e {
    border-radius: 0px !important;
    color: white !important;
    border-color: #5e699d !important;
}
.nav-item-e:hover {
    border-color: #5e699d !important;
}
.nav-link-e:hover {
    border-color: #5e699d !important;
}
.tab-pane-e {
    color: #5e699d !important;
    border: 1px solid #5e699d !important;
    border-top: none !important;
    padding: 30px 0 !important;
}
div.active{
    color: #000 !important;
}
a.active{
    color: #5e699d !important;
}
a.active > i{
    color: #5e699d !important;
}
.terms{
    cursor: pointer;
}

.importarDoc {
    display: flex;
    justify-content: center;
    align-items: center;
}
.container-spinner{
    display: flex;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
  }
legend {
    text-transform: uppercase;
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 20px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

fieldset {
  border: 1px solid #dce1e4 !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 15px 20px 15px !important;
  box-shadow:  0px 0px 0px 0px #000;
}

fieldset > legend {
  float: none;
  width:inherit; /* Or auto */
  padding:0 10px; /* To give a bit of padding on the left and right */
  border-bottom:none;
}

button:disabled{
  cursor: not-allowed !important;
}

input:disabled{
  cursor: not-allowed !important;
}
@media screen and (max-width: 400px){
    .btn-block{
        display: block;
        width: 100%;
        margin: 0 0 10px !important;
    }
}

.z-index-2{
    z-index: 20000 !important;
}
.bg-checkbox-content{
    background-color: #70707078;
    color: #212529;
    border-radius: 10px;
    padding: 10px;
}

div.active{
    color: #212529 !important;
}
a.active{
    color: #212529 !important;
}
.bg-checkbox-content{
    background-color: #70707078;
    color: #212529;
    border-radius: 10px;
    padding: 10px;
}
