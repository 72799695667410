.user-image-container {
    position: relative;
    width: 115px;
    height: 115px;
    border: 5px solid lightgray;
    border-radius: 50%;
    margin: 0 auto;
    cursor: pointer;

    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.user-image-container:hover{
    background-image: none !important;
    background-color: #000;
}
.user-image-container:hover > i{
    display: block;
}

.user-image-container > i:hover{
    opacity: 1;
}

.user-image-container > i{
    color: #fff;
    position: absolute;
    left: 30px;
    top: 30px;
    display: none;
}

.user-image-container > input{
    width: 0;
    opacity: 0;
}

.required::after {
    content: "*";
    color: red;
}

.nav-tabs-e {
    background-color: #5e699d !important;
    color: white !important;
    border-color: #5e699d !important;
    /* border-bottom: none !important; */
}
.nav-link-e {
    border-radius: 0px !important;
    color: white !important;
    border-color: #5e699d !important;
}
.nav-item-e:hover {
    border-color: #5e699d !important;
}
.nav-link-e:hover {
    border-color: #5e699d !important;
}
.tab-pane-e {
    color: #5e699d !important;
    border: 1px solid #5e699d !important;
    border-top: none !important;
    padding: 30px 0 !important;
}
div.active{
    color: #000 !important;
}
a.active{
    color: #5e699d !important;
}
a.active > i{
    color: #5e699d !important;
}
.terms{
    cursor: pointer;
}

.importarDoc {
    display: flex;
    justify-content: center;
    align-items: center;
}